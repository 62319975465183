import React, { Fragment } from "react";
import { Component } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

export default class UnityGame extends Component {
  constructor(props) {
    super(props);
    this.goFullscreen = this.goFullscreen.bind(this);
  }

  goFullscreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen().then(() => {
        window.removeEventListener("touchstart", this.handleFirstClick);
      });
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen().then(() => {
        window.removeEventListener("touchstart", this.handleFirstClick);
      });
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen().then(() => {
        window.removeEventListener("touchstart", this.handleFirstClick);
      });
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen().then(() => {
        window.removeEventListener("touchstart", this.handleFirstClick);
      });
    } else if (element.webkitEnterFullscreen) {
      element.webkitEnterFullscreen().then(() => {
        window.removeEventListener("touchstart", this.handleFirstClick);
      }); // For iOS Safari
    }
  };

  handleFirstClick = () => {
    setTimeout(() => {
      this.goFullscreen();
    }, 200);
  };

  componentDidMount() {
    window.addEventListener("touchstart", this.handleFirstClick);
    this.requestAnimationFrame();

    const cookieName = "_pk_id";
    const cookies = document.cookie.split(";");
    let sessionId = "";
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${cookieName}.`)) {
        const userIdWithSuffix = cookie.substring(cookieName.length + 1);
        sessionId = userIdWithSuffix.split("=")[1];
        sessionId = sessionId.split(".")[0];
      }
    }
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;

    if (!searchParams.has("session") && sessionId) {
      searchParams.append("session", sessionId);
      window.history.replaceState({}, "", currentUrl.toString());
    }
  }

  componentWillUnmount() {
    window.removeEventListener("touchstart", this.handleFirstClick);
  }

  requestAnimationFrame() {
    window.requestAnimationFrame(this.requestAnimationFrame.bind(this));
  }

  render() {
    return (
      <div id="unity-container" className="Game-App" style={{ textAlign: "left", padding: 0, border: 0, margin: 0 }}>
        <UnityGameProvider />
      </div>
    );
  }
}

function UnityGameProvider() {
  function handleCacheControl(url) {
    if (url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.data/) || url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  const minorVersionNumber = 7;

  const { unityProvider, isLoaded } = useUnityContext({
    loaderUrl: `game_build_v1_${minorVersionNumber}/Build/game_build_v1_${minorVersionNumber}.loader.js`,
    dataUrl: `game_build_v1_${minorVersionNumber}/Build/game_build_v1_${minorVersionNumber}.data.br`,
    frameworkUrl: `game_build_v1_${minorVersionNumber}/Build/game_build_v1_${minorVersionNumber}.framework.js.br`,
    codeUrl: `game_build_v1_${minorVersionNumber}/Build/game_build_v1_${minorVersionNumber}.wasm.br`,
    companyName: "Pshiphon Inc.",
    productName: "Tunnel Traffic",
    productVersion: `1.${minorVersionNumber}`,
    cacheControl: handleCacheControl,
  });

  return (
    <Fragment>
      {!isLoaded && (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p style={{ textAlign: "center", width: "100%" }}>Loading Tunnel Traffic... </p>
          <div className="loader">
            <div className="loaderBar" />
          </div>
        </div>
      )}
      <Unity
        unityProvider={unityProvider}
        style={{
          position: "fixed",
          background: "black",
          width: "100%",
          height: "100%",
          borderRadius: 8,
        }}
        devicePixelRatio={window.devicePixelRatio}
      />
    </Fragment>
  );
}
